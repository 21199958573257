<!-- Navigation-->
<app-navigation />
<!-- Header-->
<app-header />
<!-- About-->
<app-about />
<!-- Business -->
<app-business />
<!-- News -->
<app-news />
<!-- Contact-->
<app-contact-us />
<!-- Map-->
<app-map />
<!-- Footer-->
<app-footer />
<!-- Scroll to Top Button-->
<app-scroll-to-top />