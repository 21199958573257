import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TiandituService {
  private readonly key = "beb44e3b144bcabeb21207dc936cedf9";
  private readonly officialName = "天地图";
  private readonly officialSiteUrl = "https://www.tianditu.gov.cn/";
  private readonly markerImgUrl = "https://api.tianditu.gov.cn/v4.0/image/marker-icon.png";
  private readonly longitude = 118.762493;
  private readonly latitude = 32.040099;

  private readonly type = Object.freeze({
    vec: "vec_w",
    cva: "cva_w"
  });

  constructor() {
  }

  getKey(): string {
    return this.key;
  }

  getOfficialName(): string {
    return this.officialName;
  }

  getOfficialSiteUrl(): string {
    return this.officialSiteUrl;
  }

  getMarkerImgUrl(): string {
    return this.markerImgUrl;
  }

  getLongitude(): number {
    return this.longitude;
  }

  getLatitude(): number {
    return this.latitude;
  }

  private getRandomNumber(min: number, max: number): number {
    if (min >= max) {
      throw new Error('Min value must be less than max value');
    }

    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  private getRandomSource(mapType: any) {
    if (!mapType) {
      throw new Error('The map type is required');
    }

    return `https://t${this.getRandomNumber(0, 7)}.tianditu.gov.cn/DataServer?T=${mapType}&x={x}&y={y}&l={z}&tk=${this.key}`;
  }

  getVECMapSource() {
    return this.getRandomSource(this.type.vec);
  }

  getCVAMapSource() {
    return this.getRandomSource(this.type.cva);
  }
}
