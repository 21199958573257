import { Component, ElementRef, ViewChild, AfterViewInit, OnDestroy, inject } from '@angular/core';
import { faBars, faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { Subscription } from 'rxjs';
import { MenuToggleService } from 'src/app/services/menu-toggle.service';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.css']
})
export class NavigationComponent implements AfterViewInit, OnDestroy {
  @ViewChild('menu', { static: false }) menu: ElementRef | undefined;
  @ViewChild('toggle', { static: false }) toggle: ElementRef | undefined;

  faBars = faBars;
  faAngleRight = faAngleRight;
  toggleState = false;
  faIcon = faBars;

  private menuToggleSubscription: Subscription;

  constructor(private menuToggleService: MenuToggleService) {
    this.menuToggleSubscription = this.menuToggleService.getToggleState().subscribe(state => {
      this.handleToggle(state);
    });
  }

  ngAfterViewInit(): void {
    this.faIcon = faBars;
  }

  ngOnDestroy(): void {
    this.menuToggleSubscription.unsubscribe();
  }

  handleToggle(state: boolean): void {
    this.toggleState = state;

    this.faIcon = this.toggleState ? faAngleRight : faBars;
    this.menu?.nativeElement.classList.toggle('active');
  }

  navigating(): void {
    this.menuToggleService.closeMenu();
  }
}
