<button #toggle class="menu-toggle rounded no-border" (click)="handleToggle(!toggleState)">
    <fa-icon [icon]="faIcon" />
</button>
<nav #menu class="sidebar-wrapper">
    <ul class="sidebar-nav">
        <li class="sidebar-brand"><a href="#page-top" (click)="navigating()"></a>&nbsp;</li>
        <li class="sidebar-nav-item"><a href="#page-top" (click)="navigating()">首页</a></li>
        <li class="sidebar-nav-item"><a href="#about" (click)="navigating()">关于我们</a></li>
        <li class="sidebar-nav-item"><a href="#business" (click)="navigating()">主要业务</a></li>
        <li class="sidebar-nav-item"><a href="#news" (click)="navigating()">最新资讯</a></li>
        <li class="sidebar-nav-item"><a href="#contact" (click)="navigating()">联系方式</a></li>
    </ul>
</nav>