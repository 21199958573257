import { Component, ElementRef, ViewChild, AfterViewInit } from '@angular/core';
import { Map, View } from 'ol';
import { OpenlayersService } from 'src/app/services/openlayers.service';

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.css'],
})
export class MapComponent implements AfterViewInit {
  constructor(private openlayerService: OpenlayersService) {
  }

  ngAfterViewInit(): void {
    this.openlayerService.updateSize('map');
    // this.openlayerService.markAtCenter();
  }
}
