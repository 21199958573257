import { Component } from '@angular/core';
import { faBars, faMobileScreenButton, faPencil, faCity, faCamera, faTrowelBricks } from '@fortawesome/free-solid-svg-icons';
import { faHeart, faCircleQuestion } from '@fortawesome/free-regular-svg-icons';
import { faWeibo } from '@fortawesome/free-brands-svg-icons';

@Component({
  selector: 'app-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.css']
})
export class NewsComponent {
  faTrowelBricks = faTrowelBricks;
  faCity = faCity;
  faWeibo = faWeibo;
  faCamera = faCamera;
}
