import { Injectable } from '@angular/core';
import { Feature, Map, View } from 'ol';
import { defaults as defaultControls } from 'ol/control';
import { defaults as defaultInteractions } from 'ol/interaction';
import { XYZ } from 'ol/source';
import TileLayer from 'ol/layer/Tile';
import { TiandituService } from './tianditu.service';
import TileSource from 'ol/source/Tile';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import Icon from 'ol/style/Icon';
import Style from 'ol/style/Style';
import { fromLonLat } from 'ol/proj';
import { Point } from 'ol/geom';

@Injectable({
  providedIn: 'root'
})
export class OpenlayersService {

  private readonly map: Map;
  private readonly view: View;
  private readonly marker: Feature;

  constructor(
    private tiandituService: TiandituService
  ) {

    this.view = new View({
      projection: "EPSG:900913",
      constrainResolution: true,
      center: fromLonLat([this.tiandituService.getLongitude(), this.tiandituService.getLatitude()]),
      minZoom: 8,
      zoom: 14,
      maxZoom: 18
    });

    this.map = new Map({
      view: this.view,
      layers: [
        this.createMapLayer(this.tiandituService.getVECMapSource()),
        this.createMapLayer(this.tiandituService.getCVAMapSource())
      ],
      interactions: defaultInteractions({
        onFocusOnly: true,
        doubleClickZoom: false,
        mouseWheelZoom: false,
        altShiftDragRotate: false,
        keyboard: false,
        shiftDragZoom: false,
        dragPan: true,
        pinchRotate: false,
        pinchZoom: true
      }),
      controls: defaultControls({
        zoom: true
      })
    });

    this.marker = new Feature({
      geometry: new Point(fromLonLat([this.tiandituService.getLongitude(), this.tiandituService.getLatitude()]))
    });
    this.createMarkerLayer();
  }

  updateView(zoom = 2, center: [number, number] = [0, 0]): void {
    this.view.setZoom(zoom);
    this.view.setCenter(fromLonLat(center));
  }

  updateSize(target = 'map'): void {
    this.map.setTarget(target);
    this.map.updateSize();
  }

  markAtCenter(): void {
    if (this.view.getCenter()) {
      const coordinate = fromLonLat(this.map.getView().getCenter() || [0, 0]);
      this.marker.setGeometry(new Point(coordinate));
    }
  }

  private createDataSource(sourceUrl: string): TileSource {
    const attributions = `<a href="${this.tiandituService.getOfficialSiteUrl}" target="_blank">&copy; ${this.tiandituService.getOfficialName}</a>`;
    return new XYZ({
      url: sourceUrl,
      attributions: attributions,
      minZoom: 2,
      maxZoom: 18
    });
  }

  private createMapLayer(sourceUrl: string): TileLayer<TileSource> {
    return new TileLayer({
      preload: 4,
      source: this.createDataSource(sourceUrl)
    });
  }

  private createMarkerLayer(): void {
    console.log('OpenlayersService.createMarkerLayer()');
    const markerIcon = new Icon({
      anchor: [0.5, 1],
      src: this.tiandituService.getMarkerImgUrl()
    });
    const markerStyle = new Style({
      image: markerIcon
    });

    this.marker.setStyle(markerStyle);
    const markerSource = new VectorSource({
      features: [this.marker]
    });
    const markerLayer = new VectorLayer({
      source: markerSource
    });
    this.map.addLayer(markerLayer);
  }
}
