<section class="content-section bg-light" id="about">
    <div class="container px-4 px-lg-5 text-center">
        <div class="row gx-4 gx-lg-5 justify-content-center">
            <div class="col-lg-12">
                <h2>提供工业与民用建筑、园林景观、室内装潢、市政道路桥梁设计技术咨询服务</h2>
                <p class="lead mb-5">
                    &nbsp;
                </p>
                <a class="btn btn-dark btn-xl" href="#business">核心服务</a>
            </div>
        </div>
    </div>
</section>