import { Component, ElementRef, HostListener, ViewChild } from '@angular/core';
import { faHandPointUp } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  @ViewChild('scrollToTop', { static: false }) scrollToTop: ElementRef | undefined;
  title = '江苏城市建设经营网';
  faHandPointUp = faHandPointUp;
  scrollToTopVisible = false;

  @HostListener('document:scroll', ['$event.target'])
  onWindowScroll(target: Document): void {
    if (document.documentElement.scrollTop > 100) {
      if (!this.scrollToTopVisible) {
        // this.fadeIn(scrollToTop.nativeElement);
        this.scrollToTopVisible = true;
      }
    } else {
      if (this.scrollToTopVisible) {
        // this.fadeOut(scrollToTop);
        this.scrollToTopVisible = false;
      }
    }
  }
}
