<section class="content-section bg-primary text-white text-center news-bg" id="news">
    <div class="container px-4 px-lg-5">
        <div class="content-section-heading">
            <h2 class="mb-5">最新资讯</h2>
        </div>
        <div class="row gx-4 gx-lg-5">
            <div class="col-lg-3 col-md-6 mb-5 mb-lg-0">
                <span class="service-icon rounded-circle mx-auto mb-3 align-middle"><fa-icon
                        [icon]="faTrowelBricks" /></span>
                <h4><strong>建设新闻</strong></h4>
                <ul class="news-item">
                    <li>
                        顾小平带队调研南京南部新城建设情况&nbsp;&nbsp;&nbsp;<a href="#news">更多...</a>
                    </li>
                    <li>
                        江苏推动进沪建筑企业持续稳定发展&nbsp;&nbsp;&nbsp;<a href="#news">更多...</a>
                    </li>
                </ul>
            </div>
            <div class="col-lg-3 col-md-6 mb-5 mb-lg-0">
                <span class="service-icon rounded-circle mx-auto mb-3"><fa-icon [icon]="faCity" /></span>
                <h4><strong>市县动态</strong></h4>
                <ul class="news-item">
                    <li>
                        连云港市全市年内改造老旧小区60个&nbsp;&nbsp;&nbsp;<a href="#news">更多...</a>
                    </li>
                    <li>
                        立足民生保障创新转型驱动奋力续写扬中住房和城乡建设事业新篇章&nbsp;&nbsp;&nbsp;<a href="#news">更多...</a>
                    </li>
                </ul>
            </div>
            <div class="col-lg-3 col-md-6 mb-5 mb-md-0">
                <span class="service-icon rounded-circle mx-auto mb-3"><fa-icon [icon]="faWeibo" /></span>
                <h4><strong>媒体关注</strong></h4>
                <ul class="news-item">
                    <li>
                        《中国建设报》苏州去年污水处理量突破 10 亿&nbsp;&nbsp;&nbsp;<a href="#news">更多...</a>
                    </li>
                    <li>
                        全省建筑节能与科研设计工作会议在南通召开&nbsp;&nbsp;&nbsp;<a href="#news">更多...</a>
                    </li>
                </ul>
            </div>
            <div class="col-lg-3 col-md-6">
                <span class="service-icon rounded-circle mx-auto mb-3"><fa-icon [icon]="faCamera" /></span>
                <h4><strong>图片新闻</strong></h4>
                <ul class="news-item">
                    <li>
                        江苏召开＂263＂专项行动建设系统推进会&nbsp;&nbsp;&nbsp;<a href="#news">更多...</a>
                    </li>
                    <li>
                        《新华日报》＂十三五＂江苏城市市政基础设施投资需求超万亿&nbsp;&nbsp;&nbsp;<a href="#news">更多...</a>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</section>