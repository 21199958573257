<section class="content-section" id="business">
    <div class="container px-4 px-lg-5">
        <div class="content-section-heading text-center">
            <h2 class="mb-5">主营业务</h2>
        </div>
        <div class="row gx-0">
            <div class="col-lg-6">
                <a class="portfolio-item text-center" href="#!">
                    <img class="img-fluid" src="../../../assets/img/ourwork-1.png" alt="..." />
                </a>
            </div>
            <div class="col-lg-6">
                <a class="portfolio-item text-center" href="#!">
                    <img class="img-fluid" src="../../../assets/img/ourwork-2.png" alt="..." />
                </a>
            </div>
            <div class="col-lg-6">
                <a class="portfolio-item text-center" href="#!">
                    <img class="img-fluid" src="../../../assets/img/ourwork-3.png" alt="..." />
                </a>
            </div>
            <div class="col-lg-6">
                <a class="portfolio-item text-center" href="#!">
                    <img class="img-fluid" src="../../../assets/img/ourwork-4.png" alt="..." />
                </a>
            </div>
        </div>
    </div>
</section>