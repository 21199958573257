import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MenuToggleService {

  private toggleState: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor() { }

  toggleMenu(): void {
    this.toggleState.next(!this.toggleState);
  }

  closeMenu(): void {
    this.toggleState.next(false);
  }

  setToggleState(state: boolean): void {
    this.toggleState.next(state);
  }

  getToggleState(): Observable<boolean> {
    return this.toggleState.asObservable();
  }
}
