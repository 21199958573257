<section class="content-section bg-light" id="contact">
    <div class="container px-4 px-lg-5 text-center">
        <div class="row gx-4 gx-lg-5 justify-content-center">
            <div class="col-lg-12">
                <h4>
                    <strong>江苏城市建设经营网</strong>
                </h4>
                <p>
                    主办单位：江苏博苑建筑设计研究有限公司
                    <br />南京市秦淮区虎踞南路34号翔宇大厦北门6楼
                </p>
                <p>
                    <span><fa-icon [icon]="faPhone" />&nbsp;（025）86669307</span>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <span><fa-icon [icon]="faEnvelope" />&nbsp;
                        <a href="mailto:name@example.com">194575597@qq.com</a></span>
                </p>
            </div>
        </div>
    </div>
</section>